<div class="row mb-3">
  <div class="col-md-4 d-flex align-items-center justify-content-md-end mb-1 mb-md-0 text-end chip-font">{{distName}}</div>
  <div class="col-md-6 form-group">
    <mat-chip-grid class="form-control px-2 py-1" #chipGrid aria-label="Enter email or fax">
      <div *ngFor="let email of emails">
        <mat-chip-row
          class="chip-size"
          (removed)="remove(email)"
          [editable]="true"
          (edited)="edit(email, $event)"
          [aria-description]="'press enter to edit ' + email">
          {{email}}
          <button matChipRemove [attr.aria-label]="'remove ' + email">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      </div>
      <input
            class="chip-input chip-font"
            placeholder="Add email or fax..."
            [matChipInputFor]="chipGrid"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="add($event)"/>
    </mat-chip-grid>
  </div>
  <div class="col-md-2 form-group">
    <select name="IncludeAttach" #IncludeAttach="ngModel" [(ngModel)]="companyDist.IncludeAttach" class="form-select form-control">
      <option value="null">No</option>
      <option value="Y">Yes</option>
    </select>
  </div>
</div>