<div>
    <app-appheader [selectedPic]="selectedHdr"></app-appheader>
    <div class="page-container">
        <div class="page-content container">
            <div class="mb-3 d-flex flex-column flex-md-row">
                <div class="mb-3 col-md-6">
                    <h4>Company Settings</h4>
                    <div>Manage company information</div>
                </div>
                <div class="col-md-6">
                    <div class="row mb-3">
                        <div class="col-md-2 d-flex align-items-center justify-content-md-end mb-1 mb-md-0">Company</div>
                        <div class="form-group col-md-10">
                            <input type="text" size="100" name="CompanyName" class="form-control" #CompanyName="ngModel" [(ngModel)]="company.CompanyName" disabled/>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-2 d-flex align-items-center justify-content-md-end my-1 my-md-0">Address</div>
                        <div class="form-group col-md-10">
                            <input type="text" size="100" name="Address1" #Address1="ngModel" [(ngModel)]="company.Address1" class="form-control"/>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-2 d-flex align-items-center justify-content-md-end text-end my-1 my-md-0">Address #2</div>
                        <div class="form-group col-md-10">
                            <input type="text" size="100" name="Address2" #Address2="ngModel" [(ngModel)]="company.Address2" class="form-control"/>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-2 d-flex align-items-center justify-content-md-end my-1 my-md-0">City</div>
                        <div class="form-group col-md-10">
                            <input type="text" size="100" name="CityName" #CityName="ngModel" [(ngModel)]="company.CityName" class="form-control"/>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-2 d-flex align-items-center justify-content-md-end mb-1 mb-md-0">State</div>
                        <div class="form-group col-md-4">
                            <input type="text" size="100" name="StateCd" #StateCd="ngModel" [(ngModel)]="company.StateCd" class="form-control"/>
                        </div>
                        <div class="col-md-2 d-flex align-items-center justify-content-md-end my-1 my-md-0">Zip</div>
                        <div class="form-group col-md-4">
                            <input type="text" size="15" name="ZipCd" #ZipCd="ngModel" [(ngModel)]="company.ZipCd" class="form-control"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2 d-flex align-items-center justify-content-md-end mb-1 mb-md-0">Logo</div>
                        <div class="col-md-10 d-flex flex-column">
                            <img src="../../assets/Logos/{{logoFile}}" class="img-logo" height="50"/>
                            <div class="row">
                                <div class="logo-inst">File should be a jpg or png and be 50 to 75 pixels high</div>
                                <form enctype="multipart/form-data">
                                    <span class="btn btn-outline-primary btn-sm btn-file rounded-pill px-4">
                                        {{uploadBtnTitle}}
                                        <input
                                            #fileInput
                                            name="fileToUpload"
                                            (change)="handleFileInput($event.target.files)"
                                            type="file"
                                            accept="image/jpg, image/png"
                                            [disabled]="(fileState!= 1)">
                                    </span>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex justify-content-end mt-3">
                        <button class="btn btn-primary rounded-pill px-4" (click)="onSave()">Save</button>
                    </div>
                </div>
            </div>
            <hr class="my-5"/>
            <div class="mb-3 d-flex flex-column flex-md-row">
                <div class="mb-3 col-md-6">
                    <h4>User Settings</h4>
                    <div>Customize application experience and manage user onboarding</div>
                </div>
                <div class="col-md-6 d-flex flex-column justify-content-between">
                    <form [formGroup]="userSettings">
                        <div class="row">
                            <div class="col-md-2 d-flex align-items-center justify-content-md-end text-end mb-1 mb-md-0">User Title</div>
                            <div class="form-group col-md-10">
                                <input type="text" size="20" name="CustomerTitle" formControlName="CustomerTitle" class="form-control" [ngClass]="{ 'is-invalid': saveSubmitted && u.CustomerTitle.errors }"/>
                                <div *ngIf="u.CustomerTitle.errors" class="invalid-feedback">
                                    <div *ngIf="u.CustomerTitle.errors.required">User Title is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-2"></div>
                            <div class="form-group col-md-10">
                                <div class="logo-inst">(Associate, Employee, etc.)</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2 d-flex align-items-center justify-content-md-end text-end mb-1 mb-md-0">User Group Title</div>
                            <div class="form-group col-md-10">
                                <input type="text" size="20" formControlName="CustomerGroupTitle" class="form-control" [ngClass]="{ 'is-invalid': saveSubmitted && u.CustomerGroupTitle.errors }"/>
                                <div *ngIf="u.CustomerGroupTitle.errors" class="invalid-feedback">
                                    <div *ngIf="u.CustomerGroupTitle.errors.required">User Group Title is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-2"></div>
                            <div class="form-group col-md-10">
                                <div class="logo-inst">(Location, State, Department, etc.)</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2 d-flex align-items-center justify-content-md-end text-end mb-1 mb-md-0">Allow User Account Creation</div>
                            <div class="form-group col-md-10">
                                <select type="text" name="NewCustMethod" formControlName="NewCustMethod" class="form-select form-control" [ngClass]="{ 'is-invalid': saveSubmitted && u.NewCustMethod.errors }">
                                    <option value="C">Yes - Using company email</option>
                                    <option value="N">Yes - Using any email</option>
                                    <option value="A">No</option>
                                </select>
                                <div *ngIf="u.NewCustMethod.errors" class="invalid-feedback">
                                    <div *ngIf="u.NewCustMethod.errors.required">Required</div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-2"></div>
                            <div class="form-group col-md-10">
                                <div class="logo-inst">(This allows the user to create an account and access their dashboard)</div>
                            </div>
                        </div>
                        <div *ngIf="u.NewCustMethod.value === 'C'">
                            <div class="row">
                                <div class="col-md-2 d-flex align-items-center justify-content-md-end text-end mb-1 mb-md-0">Email Domain</div>
                                <div class="form-group col-md-10">
                                    <input type="text" size="100" formControlName="EmailDomain" class="form-control" [ngClass]="{ 'is-invalid': saveSubmitted && u.EmailDomain.errors }"/>
                                    <div *ngIf="u.EmailDomain.errors" class="invalid-feedback">
                                        <div *ngIf="u.EmailDomain.errors.required">Email domain is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-2"></div>
                                <div class="form-group col-md-10">
                                    <div class="logo-inst">(ex: company.com)</div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="u.NewCustMethod.value !== 'A'">
                            <div class="row">
                                <div class="col-md-2 d-flex align-items-center justify-content-md-end text-end mb-1 mb-md-0">Activation Code</div>
                                <div class="form-group col-md-10">
                                    <input type="text" size="100" formControlName="ActivationCd" class="form-control" [ngClass]="{ 'is-invalid': saveSubmitted && u.ActivationCd.errors }"/>
                                    <div *ngIf="u.ActivationCd.errors" class="invalid-feedback">
                                        <div *ngIf="u.ActivationCd.errors.required">Activation code is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-2"></div>
                                <div class="form-group col-md-10">
                                    <div class="logo-inst">(This is used by the user to create an account with your company)</div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end mt-3">
                            <button class="btn btn-primary rounded-pill px-4" (click)="onSave('userSettings')">Save</button>
                        </div>
                    </form>
                </div>
            </div>
            <hr class="my-5"/>
            <div class="mb-3 d-flex flex-column flex-md-row">
                <div class="mb-3 col-md-4">
                    <h4>Orders Settings</h4>
                    <div>Manage information used for orders</div>
                </div>
                <div class="col-md-8 d-flex flex-column justify-content-between">
                    <app-company-form-data *ngFor="let dat of formData" [setData]="dat"></app-company-form-data>
                    <div class="d-flex justify-content-end mt-3">
                        <button class="btn btn-primary rounded-pill px-4" (click)="onSaveData()">Save</button>
                    </div>
                </div>
            </div>
            <hr class="my-5"/>
            <div class="mb-3 d-flex flex-column flex-md-row">
                <div class="mb-3 col-md-4">
                    <h4>Distribution Settings</h4>
                    <div>Manage email or fax recipients for each distribution group. Fax numbers should start with the number 1 and have no spaces or parentheses.</div>
                </div>
                <div class="col-md-8 d-flex flex-column justify-content-between">
                    <div class="d-flex justify-content-end mt-3">
                        Attachments
                    </div>
                    <div *ngFor="let group of distSettings">
                        <company-dist-group [setCompanyDist]="group" [setDistName]="displayDistGroup(group.DistributionGroupId)"></company-dist-group>
                    </div>
                    <div class="d-flex justify-content-end mt-3">
                        <button class="btn btn-primary rounded-pill px-4" (click)="onSaveDist()">Save</button>
                    </div>
                </div>
            </div>
            <hr class="my-5"/>
            <div class="mb-3 d-flex flex-column flex-md-row">
                <div class="mb-3 col-md-6">
                    <h4>Subscription & Payments</h4>
                    <div>Edit subscription and payment information</div>
                </div>
                <div class="sub-border rounded p-3 col-md-6 d-flex flex-column justify-content-between">
                    <div class="d-flex justify-content-between flex-column flex-md-row">
                        <div>
                            <h6>{{subscription.SubscriptionName}}</h6>
                            <div class="text-secondary pt-1"  [innerHtml]="subscription.SubscriptionDesc"></div>
                        </div>
                        <div class="price-container pt-3 pt-md-0 pe-0">
                            <b>${{subscription.SubscriptionPrice?.toFixed(0)}}</b>
                            <div class="text-secondary">{{subscription.ChargeFreq === 'M' ? '/month' : '/year'}}</div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end text-end">
                        <button class="btn btn-link p-0" (click)="onManageSubscription()" *ngIf="companySubscription.CancelRequest == 'N'">Manage Subscription</button>
                        <div class="text-secondary pt-1" *ngIf="companySubscription.CancelRequest == 'C'">(Cancelled)</div>
                        <div class="text-secondary pt-1" *ngIf="companySubscription.CancelRequest == 'Y'">(Cancellation Pending)</div>
                    </div>
                </div>
            </div>
            <hr class="my-5"/>
            <div class="mb-3 d-flex flex-column flex-md-row">
                <div class="mb-3 col-md-4 pe-3">
                    <h4>Administrative Accounts</h4>
                    <div>Manage logins that can create packages, orders, and employees</div>
                </div>
                <div class="col-md-8 overflow-x-hidden">
                    <div class="row align-items-end">
                        <div class="col-2 d-none d-lg-block bold">First Name</div>
                        <div class="col-2 d-none d-lg-block bold">Last Name</div>
                        <div class="col-8 col-md-6 d-block bold">Email</div>
                        <div class="col-4 col-md-2 d-block bold">Primary Contact</div>
                        <hr class="my-1"/>
                    </div>
                    <div *ngFor="let logs of logins" (click)="onEditLogin(logs)" class="tbl-hover">
                        <div class="row align-items-end">
                            <div class="col-2 d-none d-lg-block">{{logs.FirstName}}</div>
                            <div class="col-2 d-none d-lg-block">{{logs.LastName}}</div>
                            <div class="col-8 col-md-6 d-block">{{logs.EmailAddress}}</div>
                            <div class="col-4 col-md-2 d-block">{{getPriContact(logs.PrimaryContact)}}</div>
                            <hr class="my-1"/>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end">
                        <button class="btn btn-link pe-0" (click)="onNewLogin()">Add Login</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
