<div class="p-4">
    <h3 class="tg-top-h">{{winTitle}}</h3>
    <nav class="navbar navbar-expand navbar-light bg-white bdr p-0 mb-3" id="employeeNav">
        <ul class="navbar-nav d-flex">
            <li class="nav-item p-1 pb-0" [ngClass]="{'selected': tab == 0}">
                <button class="btn btn-link nav-link" [ngClass]="{'active': tab == 0}" (click)="onTabSelect(0)">Contact Info</button>
            </li>
            <li class="nav-item p-1 pb-0" [ngClass]="{'selected': tab == 1}">
                <button class="btn btn-link nav-link" [ngClass]="{'active': tab == 1}" (click)="onTabSelect(1)">Employment Info</button>
            </li>
        </ul>
    </nav>
    <form autocomplete="off" [formGroup]="userDetails" (ngSubmit)="onSave()" class="form-container">
        <div *ngIf="tab === 0">
            <div class="form-group row mb-0 mb-md-2">
                <div class="col-md-4">
                    <div class="field-name my-1 text-secondary">First Name <span class="req-field">*</span></div>
                    <input type="text" formControlName="FirstName" [ngClass]="{ 'is-invalid': checked && fUser.FirstName.errors }"
                        class="form-control mb-1" required maxlength="50"/>
                </div>
                <div class="col-md-3">
                    <div class="field-name my-1 text-secondary">Middle Name</div>
                    <input type="text"  formControlName="MiddleName" [ngClass]="{ 'is-invalid': checked && fUser.MiddleName.errors }"
                        class="form-control mb-1" maxlength="50"/>
                </div>
                <div class="col-md-5">
                    <div class="field-name my-1 text-secondary">Last Name <span class="req-field">*</span></div>
                    <input type="text" formControlName="LastName" [ngClass]="{ 'is-invalid': checked && fUser.LastName.errors }"
                        class="form-control mb-1" required maxlength="50"/>
                </div>
            </div>
            <div class="form-group row mb-0 mb-md-2">
                <div class="col-md-6">
                    <div class="field-name my-1 text-secondary">Email Address <span class="req-field">*</span></div>
                    <input type="text" formControlName="EmailAddress" name="email" [ngClass]="{ 'is-invalid': checked && fUser.EmailAddress.errors }"
                        class="form-control mb-1" required maxlength="100"/>
                    <div *ngIf="checked && fUser.EmailAddress.errors" class="invalid-feedback">
                        <div *ngIf="fUser.EmailAddress.errors.email">Must be a valid email</div>
                        <div *ngIf="fUser.EmailAddress.errors.emailExists">An account with this email already exists</div>
                    </div>
                </div>
                <div class="col-md-6" *ngIf="(userService.dialogMode === 2)">
                    <div class="field-name my-1 text-secondary">Confirm Email Address <span class="req-field">*</span></div>
                    <input type="text" formControlName="EmailConfirmed" [ngClass]="{ 'is-invalid': checked && fUser.EmailConfirmed.errors }"
                        class="form-control mb-1" required maxlength="100"/>
                        <div *ngIf="checked && fUser.EmailConfirmed.errors" class="invalid-feedback">
                            <div *ngIf="fUser.EmailConfirmed.errors.confirmedValidator">Confirm Email Address must match Email Address</div>
                        </div>
                </div>
            </div>
            <div class="form-group row mb-0 mb-md-2">
                <div class="col-md-6">
                    <div class="field-name my-1 text-secondary">Password <span class="req-field">*</span></div>
                    <input type="password" formControlName="Password" [ngClass]="{ 'is-invalid': checked && fUser.Password.errors }"
                        class="form-control mb-1" required maxlength="20" autocomplete="off"/>
                    <div *ngIf="checked && fUser.Password.errors" class="invalid-feedback">
                        <div *ngIf="fUser.Password.errors.minlength">Password must be at least 6 characters</div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="field-name my-1 text-secondary">Confirm Password <span class="req-field">*</span></div>
                    <input type="password" formControlName="PasswordConfirmed" [ngClass]="{ 'is-invalid': checked && fUser.PasswordConfirmed.errors }"
                        class="form-control mb-1" required maxlength="20" autocomplete="off" />
                    <div *ngIf="checked && fUser.PasswordConfirmed.errors" class="invalid-feedback">
                        <div *ngIf="fUser.PasswordConfirmed.errors.confirmedValidator">Confirm Password must match Password</div>
                    </div>
                </div>
            </div>
            <div class="form-group row mb-0 mb-md-2">
                <div class="col-md-4">
                    <div class="field-name my-1 text-secondary">Phone Number</div>
                    <input type="text" formControlName="PhoneNumber"
                        class="form-control mb-1" maxlength="20"/>
                </div>
                <div class="col-md-4">
                    <div class="field-name mt-1 text-secondary">Birth Date</div>
                    <div class="d-flex align-items-center">
                        <input type="text" formControlName="BirthDt" name="birthdate" placeholder="mm/dd/yyyy" [ngClass]="{ 'is-invalid': checked && fUser.BirthDt.errors }"
                            class="form-control mb-1" [matDatepicker]="BirthDtPicker"/>
                        <mat-datepicker-toggle class="form-control-datepicker datepicker" matSuffix [for]="BirthDtPicker"></mat-datepicker-toggle>
                        <mat-datepicker #BirthDtPicker></mat-datepicker>
                    </div>
                </div> 
                <div class="col-md-4">
                    <div class="field-name my-1 text-secondary">Title <span class="req-field">*</span></div>
                    <input type="text" formControlName="Roles" name="roles" [ngClass]="{ 'is-invalid': checked && fUser.Roles.errors }"
                        class="form-control mb-1" maxlength="30" required/>
                </div>
            </div>
            <div class="form-group row mb-0 mb-md-2">
                <div class="col-md-9">
                    <div class="field-name my-1 text-secondary">Street Address</div>
                    <input type="text" formControlName="Address1" [ngClass]="{ 'is-invalid': checked && fUser.Address1.errors }"
                        class="form-control mb-1" maxlength="50"/>
                </div>
                <div class="col-md-3">
                    <div class="field-name my-1 text-secondary">Apt, suite, etc.</div>
                    <input type="text" formControlName="Address2" [ngClass]="{ 'is-invalid': checked && fUser.Address2.errors }"
                        class="form-control mb-1" maxlength="50"/>
                </div>
            </div>
            <div class="form-group row mb-0 mb-md-2">
                <div class="col-md-6">
                    <div class="field-name my-1 text-secondary">City</div>
                    <input type="text" formControlName="CityName" [ngClass]="{ 'is-invalid': checked && fUser.CityName.errors }"
                        class="form-control mb-1" maxlength="50"/>
                </div>
                <div class="col-md-3">
                    <div class="field-name my-1 text-secondary">State</div>
                    <select formControlName="StateCd" class="form-control form-select mb-1" [ngClass]="{ 'is-invalid': checked && fUser.StateCd.errors }">
                        <option value="" selected></option>
                        <option *ngFor="let j of states" value="{{j.code}}">{{j.code}}</option>  
                    </select>
                </div>
                <div class="col-md-3">
                    <div class="field-name my-1 text-secondary">Zip Code</div>
                    <input type="text" formControlName="ZipCd" [ngClass]="{ 'is-invalid': checked && fUser.ZipCd.errors }"
                        class="form-control mb-1" maxlength="50"/>
                </div>
            </div>
        </div>
        <div *ngIf="tab === 1" class="tab-container">
            <div class="form-group row mb-0 mb-md-3" *ngIf="(userService.dialogMode >= 1)">
                <div class="col-md-5">
                    <div class="field-name my-1 text-secondary">Supervisor Name</div>
                    <input type="text" formControlName="SupervisorName" name="supervisorName" [ngClass]="{ 'is-invalid': checked && fUser.SupervisorName.errors }"
                        class="form-control mb-1" maxlength="100"/>
                </div>
                <div class="col-md-4">
                    <div class="field-name my-1 text-secondary">{{userService.currentCompany ? userService.currentCompany.CustomerGroupTitle : 'User'}}</div>
                    <input type="text" formControlName="GroupDes"
                        class="form-control mb-1" maxlength="30"/>
                </div>
                <div class="col-md-3">
                    <div class="field-name my-1 text-secondary">Work State</div>
                    <select formControlName="WorkState" class="form-control form-select mb-1" [ngClass]="{ 'is-invalid': checked && fUser.WorkState.errors }">
                        <option value="" selected></option>
                        <option *ngFor="let j of states" value="{{j.code}}">{{j.code}}</option>  
                    </select>
                </div>
            </div>
            <div class="form-group row mb-0 mb-md-3" *ngIf="(userService.dialogMode >= 1)">
                <div class="col-md-5">
                    <div class="field-name my-1 text-secondary">Union Name</div>
                    <input type="text" formControlName="UnionName" name="unionName" [ngClass]="{ 'is-invalid': checked && fUser.UnionName.errors }"
                        class="form-control mb-1" maxlength="100"/>
                </div>
                <div class="col-md-4">
                    <div class="field-name mt-1 text-secondary">Hire Date</div>
                    <div class="d-flex align-items-center">
                        <input type="text" formControlName="HireDt" name="hiredate" placeholder="mm/dd/yyyy" [ngClass]="{ 'is-invalid': checked && fUser.HireDt.errors }"
                            class="form-control mb-1" [matDatepicker]="HireDtPicker"/>
                        <mat-datepicker-toggle class="form-control-datepicker datepicker" matSuffix [for]="HireDtPicker"></mat-datepicker-toggle>
                        <mat-datepicker #HireDtPicker></mat-datepicker>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="field-name my-1 text-secondary">Active</div>
                    <select type="text" formControlName="ActiveFlg" class="form-control form-select mb-1">
                        <option value="Y">Yes</option>
                        <option value="N">No</option>
                    </select>
                </div>
            </div>
        </div>
        <br>
        <div class="col-md-12 d-flex flex-column flex-sm-row justify-content-between align-items-start">
            <button class="btn btn-link" (click)="onDash()" *ngIf="(userService.dialogMode === 1)">View Orders</button>
            <div class="ms-auto">
                <button class="btn btn-outline-primary rounded-pill px-4 me-2" type="button" (click)="onCancel()">Cancel</button>
                <button class="btn btn-primary px-4 rounded-pill" type="submit">{{saveText}}</button>
            </div>
        </div>
    </form>
</div>
