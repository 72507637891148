import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { AuthenticationService } from '../services/authentication.service';
import { AlertService } from '../services/alert.service';
import { UserService } from '../services/user.service';
import { CompanyLogin, Customer, GenericLong, LoginParams } from '../models/customer';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ConfirmedValidator } from '../helpers/pw-validator';
import { OrderService } from '../services/order.service';

@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    adminForm: UntypedFormGroup = new UntypedFormGroup({});
    loginForm: UntypedFormGroup = new UntypedFormGroup({});
    forgotForm: UntypedFormGroup = new UntypedFormGroup({});
    newloginForm: UntypedFormGroup = new UntypedFormGroup({});
    newCustomerForm: UntypedFormGroup = new UntypedFormGroup({});;
    loading = false;
    adminLoading = false;
    submitted = false;
    adminSubmitted = false;
    returnUrl: string;
    customer: Customer;
    loginMode: string = 'login';
    loginFailed = false;
    resetTitle: string = 'Reset';

    constructor(
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthenticationService,
        private alertService: AlertService,
        private userService: UserService,
        private orderSvc: OrderService,
        private http: HttpClient
    ) {
        this.adminForm = this.formBuilder.group({
            EmailAddress: ['', Validators.required],
            PasswordCd: ['', Validators.required]
        });

        this.loginForm = this.formBuilder.group({
            EmailAddress: ['', Validators.required],
            PasswordCd: ['', Validators.required]
        });

        this.forgotForm = this.formBuilder.group({
            EmailAddress: ['', Validators.required]
        });
        
        this.newloginForm = this.formBuilder.group({
            EmailAddress: ['', Validators.required],
            EmailAddressCfrm: ['', Validators.required],
            CompanyCode: ['', Validators.required]
        }, {
            validator: [ConfirmedValidator('EmailAddress', 'EmailAddressCfrm')] //, this.checkAllowedCust()]
        });
        this.newCustomerForm = this.formBuilder.group({
            FirstName: ['', Validators.required],
            MiddleName: [''],
            LastName: ['', Validators.required],
            Password: ['', Validators.required],
            PasswordCfrm: ['', Validators.required],
            PhoneNbr: ['', Validators.required]
        }, {
            validator: ConfirmedValidator('Password', 'PasswordCfrm')
        });
    }

    ngOnInit() {
        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        //console.log("login return url is " + this.returnUrl);
    }

    // convenience getter for easy access to form fields
    get a() { return this.adminForm.controls; }
    get l() { return this.loginForm.controls; }
    get f() { return this.forgotForm.controls; }
    get n() { return this.newloginForm.controls; }
    get c() { return this.newCustomerForm.controls; }

    toForgotLogin(title: string) {
        this.submitted = false;
        this.resetTitle = title;
        this.loginMode = 'forgot';
    }

    toCreateLogin() {
        this.submitted = false;
        this.loginMode = 'createLogin';
    }

    toCreateNewAccount() {
        this.submitted = false;
        this.loginMode = 'newAccount';
    }

    toLoginMode() {
        this.submitted = false;
        this.loginMode = 'login';
    }

    onForgot()  {
        this.submitted = true;

        if (this.forgotForm.invalid) {
            return;
        }

        let cl = new CompanyLogin();
        cl.EmailAddress = this.f.EmailAddress.value;
        cl.FirstName = '.';
        cl.LastName = '.';
        cl.PasswordDes = '.';
        cl.PhoneNbr = '.';
        cl.AccessValues = '.';
        cl.PrimaryContact = '.';

        //console.log(JSON.stringify(cl));

        this.http.post(`${environment.apiUrl}/Login/Forgot/1`, cl).toPromise().then(res => {
            this.loading = false;
            this.loginMode = 'sent';
        }, error => {
            console.log(error);
        });
    }

    checkAllowedCust() {
        const code = this.newloginForm.controls['CompanyCode'];

        let cl = new LoginParams();
        cl.EmailAddress = this.newloginForm.controls['EmailAddress'].value;
        cl.PasswordCd = code.value;

        this.http.post(`${environment.apiUrl}/Login/customerallowed/`, cl).toPromise().then(res => {
            console.log("cl", cl);
            console.log("res", JSON.stringify(res));
            let rtn = res as GenericLong;
            
            if(rtn.LongValue > 0) {
                console.log('rtn: ' + rtn.LongValue);
                this.customer = new Customer();
                this.customer.CustomerId = 0;
                this.customer.CompanyId = rtn.LongValue;
                this.customer.EmailAddress = cl.EmailAddress;
                this.customer.ActiveFlg = 'Y';

                this.loading = false;
                this.loginMode = 'newCust';
                this.submitted = false
            } else {
                code.setErrors({ notAllowed: true });
            }
        }, error => {
            code.setErrors({ notAllowed: true });
        });



        /*return (formGroup: UntypedFormGroup) => {
            const email = formGroup.controls['EmailAddress'];
            const code = formGroup.controls['CompanyCode'];

            let cl = new LoginParams();
            cl.EmailAddress = email.value;
            cl.PasswordCd = code.value;

            if (code.errors && !code.errors.notAllowed) {
                return;
            }

            this.http.post(`${environment.apiUrl}/Login/customerallowed/`, cl).toPromise().then(res => {
                console.log("cl", cl);
                console.log("res", JSON.stringify(res));
                let rtn = res as GenericLong;
                
                if(rtn.LongValue > 0) {
                    console.log('rtn: ' + rtn.LongValue);
                    code.setErrors(null);
                    this.customer = new Customer();
                    this.customer.CustomerId = 0;
                    this.customer.CompanyId = rtn.LongValue;
                    this.customer.EmailAddress = cl.EmailAddress;
                    this.customer.Roles = '';
                } else {
                    code.setErrors({ notAllowed: true });
                }
            }, error => {
                code.setErrors({ notAllowed: true });
            });
        }*/
    }

    onNewLogin() {
        this.submitted = true;
        if (this.newloginForm.invalid) {
            return;
        } else {
            this.checkAllowedCust();
        }

        /*this.loading = false;
        this.loginMode = 'newCust';
        this.submitted = false;*/
    }

    onSubmit() {
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;

        this.userService.logIn(this.l.EmailAddress.value, this.l.PasswordCd.value).then(value => {
            if(value != null) {
                    this.customer = this.userService.getCustomer();
                    //console.log(JSON.stringify(this.customer));
                    this.authService.setCurrentUser(this.customer);
                    this.loading = false;
                    let url = this.returnUrl;
                    this.orderSvc.dashReturnId = this.customer.CustomerId;
                    if(url == '/') url = '/dashboard/' + this.customer.CustomerId;
                    this.router.navigate([url]);
            } else {
                this.userService.adminLogIn(this.l.EmailAddress.value, this.l.PasswordCd.value).then(value => {
                    if(value != null) {
                        this.loading = false;
                        this.orderSvc.dashReturnId = 0;
                        this.router.navigate(['dashboard/0']);
                    } else {
                        this.loginFailed = true;
                        this.alertService.error("Login failed");
                        this.loading = false;
                    }
                });
            }
        });
    }

    onSubmitCust() {
        this.submitted = true;

        if (this.newCustomerForm.invalid) {
            return;
        }

        this.customer.FirstName = this.c.FirstName.value;
        this.customer.MiddleName = this.c.MiddleName.value;
        this.customer.LastName = this.c.LastName.value;
        this.customer.PasswordCd = this.c.Password.value;
        this.customer.PhoneNbr = this.c.PhoneNbr.value;
        //console.log(JSON.stringify(this.customer));
        this.http.post(`${environment.apiUrl}/Login/CustomerSave`, this.customer).toPromise().then(res => {
            let c = res as Customer;

            this.customer = c;
            console.log(JSON.stringify(this.customer));

            this.userService.currentCustomer = c;
            this.userService.currentEmail = c.EmailAddress;
            this.userService.userRole = 'customer';

            this.authService.setCurrentUser(c);

            let url = this.returnUrl;
            if(url == '/') url = '/dashboard/' + this.customer.CustomerId;

            this.loading = false;
            this.router.navigate([url]);
        }, error => {
            console.log(error);
        });
    }

    onGuest() {
        this.router.navigate(['entry']);
    }
}
