import { Component, OnInit } from '@angular/core';
import { OrderService } from '../services/order.service';

@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.scss']
})
export class ThanksComponent implements OnInit {
  settingTitle: string = 'Now that was easy!';
  settingSubtitle: string = 'You will receive an email shortly.';
  bodyEmp: string = 'If you provided an email or fax number for your healthcare provider, this information will be forwarded for them to complete. If not, please print the pdf that just downloaded to your browser.';
  bodyHr: string = 'This order is available in the portal where you can track its progress.';
  bodyDoc: string = 'A copy of the document(s) you just completed will be emailed to you shortly, from Expidoc.';
  settingBody: string = '';
  
  constructor(public orderService:OrderService) { }

  ngOnInit(): void {
    //console.log('orderService', this.orderService);
    if(!this.orderService.pageResult) {
      this.settingTitle = 'Woops something went wrong...';
      this.settingSubtitle = 'Dont worry we`ll fix it! (' + this.orderService.pageText + ')';
    }

    this.settingBody = (this.orderService.currentTaker?.TakerHint == 'Associate') ? this.bodyEmp : 
      (this.orderService.currentTaker?.TakerHint == 'HR') ? this.bodyHr : this.bodyDoc;

    this.settingTitle = this.orderService.getSetting('ThanksTitle' + this.orderService.currentTaker.TakerHint, this.settingTitle);
    this.settingSubtitle = this.orderService.getSetting('ThanksBody' + this.orderService.currentTaker.TakerHint, this.settingSubtitle);
  }

}
