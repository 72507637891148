<div>
    <app-appheader></app-appheader>
    <div class="container page-content-2" [class.mt-4]="!userService.currentEmail" *ngIf="pageMode === 0">
        <button *ngIf="userService.currentEmail" class="btn btn-link link-secondary rounded-pill ps-0 mb-2" (click)="onBackToOrder()"><i class="fa-solid fa-chevron-left pe-1"></i>Back to Order Details</button>
        <h4 class="header">{{orderService.currentPackage.PackageName}}</h4>
        <!--button (click)="linkTest()">Test</button-->
        <h6>{{orderService.currentOrder.CustomHeader}}</h6>
        <mat-stepper linear #stepper class="stepper-container overflow-x-auto" [selectedIndex]="currentStep" (selectionChange)="selectStep($event)" [animationDuration]="0">
            <mat-step *ngFor="let pg of formService.pages; let idx = index" [completed]="isValidFormCheck(idx)">
                <ng-template matStepLabel>{{pg.ProgressTitle}}</ng-template>
            </mat-step>
        </mat-stepper>
        <h4 class="mb-2">{{formGroup.GroupTitle}}</h4>
        <p>{{formGroup.GroupDesc}}</p>
        <ng-container *ngFor="let cols of formService.cols">
            <app-form-field-cols [cols]="cols"></app-form-field-cols>
        </ng-container>
        <div class="d-flex justify-content-end mt-3 mb-5">
            <button [disabled]="loading" type="button" class="btn btn-danger rounded-pill px-4" (click)="onCancel()">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Cancel
            </button>
            <button [disabled]="(formService.pageIdx == 0)" type="button" class="btn btn-outline-primary rounded-pill px-4 ms-2" (click)="onBack()">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Back
            </button>
            <button [disabled]="loading" type="button" class="btn btn-primary rounded-pill px-4 ms-2" (click)="onNext()">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                {{nextTitle}}
            </button>
        </div>
    </div>

    <div class="container page-content-2" [class.mt-4]="!userService.currentEmail" *ngIf="pageMode === 1">
        <button *ngIf="userService.currentEmail" class="btn btn-link link-secondary rounded-pill ps-0 mb-2" (click)="onBackToOrder()"><i class="fa-solid fa-chevron-left pe-1"></i>Back to Order Details</button>
        <h4 class="header">{{orderService.currentPackage.PackageName}}</h4>
        <!--button (click)="linkTest()">Test</button-->
        <h6>{{orderService.currentOrder.CustomHeader}}</h6>
        <br>
        <div class="d-flex flex-column-reverse flex-md-row mt-4">
            <div class="col-6 col-md-6">
                <h5>Attach Files (Optional)</h5>
                <div id="drag-area" class="drag-file" (dragover)="onDragOver($event)" (drop)="onDrop($event)" (dragleave)="onDragLeave($event)">
                    <i class="fa-solid fa-arrow-up-from-bracket icon"></i>
                    <h6>Drag and Drop File(s)</h6>
                    <h6>(jpg, png or pdf only)</h6>
                    <h6>each must be under 6mb</h6>
                    <h6>Or</h6>
                    <button class="btn btn-outline-primary rounded-pill px-4 align-items-center" (click)="onChooseFile()">Browse Files</button>
                    <input type="file" id="file-upload" hidden (change)="onInputChange($event)" accept=".jpg,.png,.pdf">
                    <div class="upload-container">
                        <div *ngFor="let f of uploadedFiles;let i = index">
                            <span class="upl-file-name">{{f.name}}</span><i class="fa-solid fa-xmark exit" (click)="onRemoveUploadedFile(i)"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-1 col-md-1"></div>
            <div class="col-5 col-md-5">
                <h5>Current Attachments</h5>
                <div class="border attaches">
                    <div *ngFor="let a of attachments">{{a.AttachmentTitle}}&nbsp;&nbsp;&nbsp;<i class="fas fa-cloud-download-alt ff-title-a" (click)="onAsset(a.AttachmentName)"></i></div>
                </div>
            </div>
        </div>
        <!--form enctype="multipart/form-data">
            <span class="btn btn-secondary btn-sm btn-file">
                Upload Form
            <input #fileInput name="fileToUpload" (change)="handleFileInput($event.target.files)" type="file"
                            accept="application/pdf, application/msword" [disabled]="(fileState!= 1)">
            </span>{{chosenFileName}}
        </form-->
        <br>
        <button [disabled]="loading" type="button" class="btn btn-primary rounded-pill px-4 ms-2" (click)="onFinish()">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Submit
        </button>
        <button [disabled]="loading" type="button" class="btn btn-primary rounded-pill px-4 ms-2" (click)="completeFinish()">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Skip
        </button>
    </div>

    <div class="modalSpinner" *ngIf="loaderLocked">
        <div class="wait-loader"></div>
    </div>
</div>