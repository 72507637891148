<div>
    <app-appheader [selectedPic]="selectedHdr"></app-appheader>
    <div class="page-container">
        <div class="page-content-2 container">
            <button class="btn btn-link link-secondary rounded-pill ps-0 mb-2" (click)="onBack()"><i class="fa-solid fa-chevron-left pe-1"></i>Back to Orders</button>
            <div class="d-flex justify-content-between align-items-center">
                <h4>Order #{{order?.OrderId}}</h4>
                <div class="d-flex align-items-center" *ngIf="!checkCompletion()">
                    <i class="fa-solid fa-circle-info me-2 info" data-bs-toggle="tooltip" title="Fill out {{takerTitle}} section of Order #{{order?.OrderId}}" data-bs-custom-class="my-2" (mouseover)="onHover($event)"></i>
                    <button class="btn btn-primary rounded-pill px-4" (click)="onCompleteOrder()">Complete {{takerTitle}} Forms</button>
                </div>
                <button *ngIf="checkCompletion()" class="btn btn-primary rounded-pill px-4" disabled>{{takerTitle}} Forms Completed</button>
            </div>
            <div class="d-flex flex-column-reverse flex-md-row mt-4">
                <div class="col col-md-8">
                    <div>
                        <h5>Order Details</h5>
                        <div class="d-flex flex-column flex-lg-row border me-lg-3">
                            <div class="col col-lg-6">
                                <div class="d-flex mb-4">
                                    <div class="item-title">Package</div>
                                    <div>{{packageName}}</div>
                                </div>
                                <div class="d-flex">
                                    <div class="item-title">{{userService.currentCompany?.CustomerTitle}}</div>
                                    <div>{{customer?.FirstName}} {{customer?.LastName}}</div>
                                </div>
                            </div>
                            <div class="col col-lg-6">
                                <div class="d-flex mb-3 mt-lg-0 mt-4">
                                    <div class="item-title">Created on</div>
                                    <div>{{orderDate}}</div>
                                </div>
                                <div class="p-0 m-0 d-flex align-items-center" (click)="onDownload()">
                                    <div class="btn btn-link">Download order</div>
                                    <i class="fa-regular fa-file-pdf fa-pointer ps-1 m-0"></i>
                                    <span class="info-ita">&nbsp;&nbsp;(In its current status)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="me-0">
                        <div class="d-flex justify-content-between align-items-center me-lg-3">
                            <h5>Filer Details</h5>
                            <button class="btn btn-tertiary rounded-pill px-4" *ngIf="!editing" (click)="onEdit()">Edit</button>
                            <div class="d-flex flex-column flex-lg-row align-items-center" *ngIf="editing">
                                <button class="btn btn-tertiary rounded-pill px-4 me-lg-2"(click)="onCancel()">Undo changes</button>
                                <button class="btn btn-outline-primary rounded-pill px-4" (click)="onSave()">Save changes</button>
                            </div>
                        </div>
                        <div class="border me-lg-3" *ngIf="!editing">
                            <div *ngFor="let taker of takers; index as i" [ngClass]="{'border-bottom': i !== takers.length - 1}">
                                <!--div class="d-flex justify-content-between my-1">
                                    <h6>{{taker.TakerHint}}</h6>
                                    <div class="d-flex flex-column flex-md-row align-items-end">
                                        <div *ngIf="taker.SendMethod !== 'fax'" class="btn btn-link d-flex align-items-center mt-0 pt-0" (click)="onResendLink(taker)">
                                            <div>Resend invite link</div>
                                            <i class="fa-regular fa-envelope fa-pointer ps-1 mt-0"></i>
                                        </div>
                                        <div class="btn btn-link d-flex align-items-center mt-0 pt-0" (click)="onResendOrder(taker)">
                                            <div>Resend order</div>
                                            <i class="fa-regular fa-envelope fa-pointer ps-1 mt-0"></i>
                                        </div>
                                    </div>
                                </div-->
                                <div class="d-flex flex-column flex-lg-row align-items-lg-center my-1">
                                    <div class="col-lg-8 d-flex mb-6 mb-lg-0 pe-lg-1"><h6>{{taker.TakerHint}}</h6></div>
                                    <div class="col-lg-2 d-flex mb-2 mb-lg-0 pe-lg-1 item-title"></div>
                                    <div class="col-lg-2 d-flex my-2 my-lg-0 pe-lg-1 item-title"></div>
                                </div>
                                <div class="d-flex flex-column flex-lg-row align-items-lg-center my-1">
                                    <div class="col-lg-4 d-flex mb-2 mb-lg-0 pe-lg-1 item-title">Email Address</div>
                                    <div class="col-lg-2 d-flex mb-2 mb-lg-0 pe-lg-1 item-title">Fax Number</div>
                                    <div class="col-lg-2 d-flex my-2 my-lg-0 pe-lg-1 item-title">Send Method</div>
                                    <div class="col-lg-2 d-flex mb-2 mb-lg-0 pe-lg-1 item-title">Access Link</div>
                                    <div class="col-lg-2 d-flex my-2 my-lg-0 pe-lg-1 item-title">Order PDF</div>
                                </div>
                                <div class="d-flex flex-column flex-lg-row align-items-lg-center my-2">
                                    <div class="col-lg-4 pe-lg-4">{{!taker.EmailAddress ? 'none' : taker.EmailAddress}}</div>
                                    <div class="col-lg-2">{{!taker.FaxNumber ? 'none' : taker.FaxNumber}}</div>
                                    <div class="col-lg-2">{{taker.SendMethod}}</div>
                                    <div class="col-lg-2 d-flex mb-2 mb-lg-0 pe-lg-1 item-title">
                                        <div *ngIf="taker.SendMethod !== 'fax'" class="btn btn-link d-flex align-items-center mt-0 pt-0" (click)="onResendLink(taker)">
                                            <div>Send</div>
                                            <i class="fa-regular fa-envelope fa-pointer ps-1 mt-0"></i>
                                        </div>
                                    </div>
                                    <div class="col-lg-2 d-flex my-2 my-lg-0 pe-lg-1 item-title">
                                        <div class="btn btn-link d-flex align-items-center mt-0 pt-0" (click)="onResendOrder(taker)">
                                            <div>Send</div>
                                            <i class="fa-regular fa-envelope fa-pointer ps-1 mt-0"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="border me-lg-3" *ngIf="editing">
                            <div *ngFor="let taker of takerForms; index as i" [ngClass]="{'border-bottom': i !== takerForms.length - 1}">
                                <div class="my-1">
                                    <h6>{{taker.get('TakerHint').value}}</h6>
                                </div>
                                <form [formGroup]="taker">
                                    <div class="d-flex flex-column flex-lg-row align-items-lg-center">
                                        <div class="col-lg-4 d-flex mb-4 mb-lg-0 pe-lg-1 item-title">Email Address</div>
                                        <div class="col-lg-2 d-flex mb-2 mb-lg-0 pe-lg-1 item-title">Fax Number</div>
                                        <div class="col-lg-2 d-flex my-2 my-lg-0 pe-lg-1 item-title">Send Method</div>
                                        
                                    </div>
                                    <div class="d-flex flex-column flex-lg-row align-items-lg-center mt-2">
                                        <div class="form-group col-lg-4 pe-lg-3">
                                            <input type="email" class="form-control" id="emailAddress" formControlName="EmailAddress">
                                        </div>
                                        <div class="form-group col-lg-2 pe-lg-2">
                                            <input type="text" class="form-control" id="faxNumber" formControlName="FaxNumber">
                                        </div>
                                        <div class="form-group col-lg-2">
                                            <select class="form-control" id="sendMethod" formControlName="SendMethod">
                                                <option value="email">Email</option>
                                                <option value="fax">Fax</option>
                                                <option value="both">Email & Fax</option>
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col col-md-4">
                    <h5>Status</h5>
                    <div class="border">
                        <filer-status *ngIf="orderDetails" [order]="orderDetails" [details]="true"/>
                    </div>
                    <h5>Attachments</h5>
                    <div class="border attaches">
                        <div *ngFor="let a of attachments">
                            {{a.AttachmentTitle}}&nbsp;&nbsp;&nbsp;<i class="fas fa-cloud-download-alt ff-title-a" (click)="onAsset(a.AttachmentName)"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modalSpinner" *ngIf="loaderLocked">
    <div class="wait-loader"></div>
</div>
