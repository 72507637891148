import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionLike } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FormFieldComponent } from '../form-field/form-field.component';
import { PageNextStep } from '../helpers/page-next-step';
import { ModalPdfComponent } from '../modal-pdf/modal-pdf.component';
import { FieldData, FieldGroup, Form } from '../models/form';
import { AlertService } from '../services/alert.service';
import { CompanyService } from '../services/company.service';
import { FormService } from '../services/form.service';
import { OrderService } from '../services/order.service';
import { DialogService } from '../services/dialog.service';
import { OrderAttachment, OrderFormFieldData } from '../models/order';
import { saveAs } from 'file-saver';
import { UserService } from '../services/user.service';
import { GenericString } from '../models/customer';
import { ExternalLists } from '../helpers/external-lists';
import { map } from 'rxjs/operators';
import { ToastService } from '../services/toast.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {
  private sub: any;
  loading: boolean;
  nextNav: string = '';
  pageSeq: number;
  formGroup: FieldGroup;
  fields: FormFieldComponent[];
  nextTitle: string = 'Next';
  stepsDoneSubscrition: SubscriptionLike;
  loaderLocked: boolean = false;
  isPageValid: Array<boolean> = [];
  currentStep: number = 0;
  pageMode: number = 0;
  //finalNav: string;

  fileState: number = 1;
  //chosenFile: File;
  //chosenFileName: string = '';
  uploadedFiles: File[];
  attachments: OrderAttachment[];

  @ViewChild('fileInput') fileInput:ElementRef;

  constructor(private http: HttpClient, private alertService: AlertService,
    public orderService:OrderService, public formService: FormService, public companyService: CompanyService, public userService: UserService,
    private router: Router, private route: ActivatedRoute, public dialog: MatDialog, public dialogService: DialogService) { }

  ngOnInit(): void {
    this.uploadedFiles = new Array<File>();
    this.attachments = new Array<OrderAttachment>();

    this.sub = this.route.params.subscribe(params => {
      let sid = params['id'];
      this.loadData();
    });

    this.stepsDoneSubscrition = this.orderService.pageSteps$.subscribe(res =>  {
      let rtn = res as boolean;
      console.log('page steps done reporting ' + rtn + ' : ' + this.nextNav);
      if(rtn) this.router.navigate([this.nextNav]);
    });
  }

  linkTest() {
    /*let pdfFile = 'Expidoc_1-3.pdf';
    let url = `${environment.apiUrl}/PdfGen/showmail/1/3`;
      this.orderService.getPdf(url).subscribe(data => {
        saveAs(data, pdfFile);
      });*/

    const fileURL = `${environment.apiUrl}/PdfGen/showmail/1/3`;
    window.open(fileURL, '_blank');
  }

  loadData() {
    //console.log('loading data');
    this.formGroup = new FieldGroup();

    this.http.get(`${environment.apiUrl}/FormData/Order/` + this.orderService.currentOrderId).toPromise().then(res => {
      this.formService.orderData = res as OrderFormFieldData[];
      //console.log('data len: ' + this.formService.orderData.length);
      //console.log('data: ' + JSON.stringify(this.formService.orderData));

      this.formService.filterPages();
      this.pageSeq = this.formService.pages[this.formService.pageIdx].PageSeq;
      //console.log("seq is " + this.pageSeq);
  
      let url = `${environment.apiUrl}/FieldGroup/Page/` + this.orderService.currentPackageId + '/' + this.pageSeq;
      this.http.get(url).toPromise().then(res => {
        this.formGroup = res as FieldGroup;
        //console.log('group is ' + JSON.stringify(this.formGroup));
        
        this.loadGroup();
      },
      error => {
        console.log('failed to get group');
        this.alertService.error(error);
      });
    },
    error => {
      console.log('failed to get order');
      this.alertService.error(error);
    });
  }
  
  loadGroup() {
    //console.log('loading group');
    this.loading = true;

    //this.formGroup = JSON.parse('{"GroupId":0,"PackageId":0,"PageSeq":0,"GroupTitle":"loading...","GroupDesc":""}');

    let i = this.formService.pageIdx + 1;
    let l = this.formService.pages.length;

    this.nextTitle = 'Next';
    //if(i == l) this.nextTitle = 'Submit';

    /*let params: HttpParams = new HttpParams();
    params.append('formid', '' + this.orderService.currentOrder.FormId);
    params.append('pageseq', '' + this.pageSeq);*/
    //console.log(JSON.stringify(this.formService.taker));
    //let url = `${environment.apiUrl}/FieldGroup/Page/` + this.formService.taker.PackageId + "/" + this.pageSeq;
    this.loadFields();
  }

  loadFields() {
    //console.log('loading fields');
    let url = `${environment.apiUrl}/Field/GroupData/` + this.formService.nextTaker.OrderId + "/" + this.formGroup.GroupId + '/' + this.orderService.currentOrder.CustomerId;
    //console.log('field url: ' + url);

    this.http.get(url).toPromise()
    .then(res => {
      this.loading  = false;
      this.loaderLocked = false;
      this.formService.items = null;
      this.formService.initFields(0);

      let f = res as FieldData[];
      //console.log(JSON.stringify(f));

      this.formService.setItems(f);

      let len = this.formService.items.length;
      //console.log('there are ' + len + ' fields');
      this.formService.initFields(len);
    });
  }

  onBack() {
    let i = this.formService.pageIdx - 1;
    if(i >= 0) {
      this.loaderLocked = true;
      this.formService.pageIdx = i;
      this.router.navigate(['pages/' + i]);
      this.currentStep = i;
    } else {
      alert('Can`t go back');
    }
  }

  onNext() {
    this.alertService.clear();
    this.isPageValid[this.formService.pageIdx] = this.formService.validateForm();
    //console.log("valid = " + this.isPageValid[this.formService.pageIdx]);

    if(this.isPageValid[this.formService.pageIdx]) {
      this.loaderLocked = true;
      let data = this.formService.getValues(this.orderService.currentOrderId);
      //console.log("next data saving for x items: " + data.answers.length);
      //console.log("   " + data.answers[0].FieldValue);

      // later add piece to check if just saving or completed

      this.http.post(`${environment.apiUrl}/FormData/SaveData`, data)
        .subscribe(res => {
          let i = this.formService.pageIdx + 1;
          let l = this.formService.pages.length;
          //console.log('next: ' + i + ' of ' + l + ' :: next action = ' + this.formGroup.TakerNextAction);

          if(i < l && this.formGroup.TakerNextAction == 'next') {
            this.formService.pageIdx = i;
            this.router.navigate(['pages/' + i]);
            this.currentStep = i;
          } else {
            /*let h = new PageNextStep(this.orderService, this.http);
            let rtn = h.evaluate(this.formGroup.TakerNextAction);
            if(rtn == '') rtn = 'thanks';
            //console.log('onnext eval returned ' + rtn + ' :: ' + h.lastError);

            if(rtn == 'ERROR') {
              // error processing
              this.loaderLocked = false;
              this.alertService.error('Something went wrong, we`re on it...');
            } else {*/
              //console.log('h csteps: ' + h.cSteps);
              //if(h.cSteps == 0) this.router.navigate([rtn]);

              this.http.get(`${environment.apiUrl}/OrderAttachment/Order/` + this.formService.nextTaker.OrderId).toPromise().then(res => {
                this.attachments = res as OrderAttachment[];
              });

              this.loaderLocked = false;
              this.pageMode = 1;
              //this.router.navigate([rtn]);
            //}
          }
        },
        error => {
          this.loaderLocked = false;
          this.alertService.error("Error saving !!: " + error)
                  //error => this.setError("Unable to save your survey")
        }
      );
    } else {
      this.dialogService.openMessageDialog('Validate Form', 'You have an error in one or more questions above highlighted in red.  Please correct before you continue.');
    }
  }

  onFinish() {
    this.loaderLocked = true;

    //console.log('finishing with ' + this.uploadedFiles.length + ' attchments');
    if(this.uploadedFiles.length > 0) {
      this.uploadFile(0);
    } else {
      this.completeFinish();
    }
  }

  completeFinish() {
    let h = new PageNextStep(this.orderService, this.http);
    let rtn = h.evaluate(this.formGroup.TakerNextAction);
    if(rtn == '') rtn = 'thanks';
    //console.log('completenext eval returned ' + rtn + ' :: ' + h.lastError);

    if(rtn == 'ERROR') {
      // error processing
      this.loaderLocked = false;
      this.alertService.error('Something went wrong, we`re on it...');
    } else {
      this.router.navigate([rtn]);
    }
  }

  uploadFile(idx: number) {
    let f = this.uploadedFiles[idx];
    //console.log('uploading ' + idx + ' ' + f.name);

    let fileName = '' + this.orderService.currentOrderId + '_' + f.name;
    /*let ax = this.attachments.find(f => f.AttachmentName == fileName);
    if(ax != null) {
      // already exists
      this.uploadFile(idx + 1);
    } else {*/
      let oa = new OrderAttachment();
      oa.AttachmentName = fileName;
      oa.AttachmentTitle = f.name;
      oa.OrderId = this.orderService.currentOrderId;
      oa.TakerId = this.userService.currentTaker.TakerId;
      oa.UploadDate = new Date();

      this.http.post(`${environment.apiUrl}/OrderAttachment`, oa).toPromise().then(res => {
        let uploadData = new FormData();
        uploadData.append('file', f, fileName);
        this.http.post(`${environment.apiUrl}/OrderAttachment/UploadFile`, uploadData).toPromise().then(res => {
          if(this.uploadedFiles.length == idx + 1) {
            this.completeFinish();
          } else {
            this.uploadFile(idx + 1);
          }  
        });
      });
    //}
  }

  validateFile(file: File): boolean {
    let rtn = false;
    
    let fileName = '' + this.orderService.currentOrderId + '_' + file.name;
    let ax = this.attachments.find(f => f.AttachmentName == fileName);
    if(ax == null) {
      let ext = fileName.substring(fileName.lastIndexOf('.') + 1);
      if(ext == 'jpg') rtn = true;
      if(ext == 'png') rtn = true;
      if(ext == 'pdf') rtn = true;
    }

    if(rtn) {
      if(file.size > 6000000) {
        rtn = false;
        console.log('File ' + file.name + ' is too large');
        this.dialogService.openMessageDialog('Attach',  'File ' + file.name + ' is too large');
      }
    } else {
      console.log('File ' + file.name + ' is the wrong type');
      this.dialogService.openMessageDialog('Attach',  'File ' + file.name + ' is the wrong type');
    }

    return rtn;
  }

  onAsset(file: string) {
    let str = new GenericString();
    str.ValueDes = file;
    let ext = ExternalLists.mimeType(file.substring(file.lastIndexOf('.') + 1));
    this.getFile(`${environment.apiUrl}/OrderAttachment/download`, str, ext).subscribe(data => {
      saveAs(data, file);
    });
  }

  getFile(path: string, str: GenericString, typ: string): any {
    return this.http.post<any>(path, str, { responseType: 'blob' as 'json'}).pipe(map(res => {return new Blob([res], { type: typ });  }));
  }
  
  onChooseFile() {
    document.getElementById('file-upload').click()
  }

  onInputChange(event: any) {
    let f = event.target.files[0];

    if(this.validateFile(f)) this.uploadedFiles.push(f);
    //console.log('picked: ' + f.name);
  }

  onDragOver(event: any) {
    //console.log('drag over:: ' + JSON.stringify(event));
    event.preventDefault();
    document.getElementById('drag-area').classList.add("active");
  }

  onDragLeave(event: any) {
    //console.log('drag leave:: ' + JSON.stringify(event));
    event.preventDefault();
    document.getElementById('drag-area').classList.remove("active");
  }

  onDrop(event: any) {
    //console.log('dropped:: ' + JSON.stringify(event));
    event.preventDefault(); 

    //this.uploadedFiles = Array.from(event.dataTransfer.files);
    for(let a = 0; a < event.dataTransfer.files.length; a++) {
      let f = event.dataTransfer.files[a];
      if(this.validateFile(f)) this.uploadedFiles.push(f);
      //console.log('  ' + a + ' ' + f.name);
    }
  }

  onRemoveUploadedFile(idx: number) {
    this.uploadedFiles.splice(idx, 1);
  }

  onCancel() {
    this.dialogService.openConfirmDialog('Cancel order?', 'Are you sure you want to cancel this order? All data will be lost.', 'Yes, cancel').subscribe(res => {
      //console.log('cancel res: ' + res);
      if(res.event == 'cancel') {
        this.http.get(`${environment.apiUrl}/OrderForm/Cancel/` + this.orderService.currentOrderId).toPromise().then(res => {
          this.router.navigateByUrl('/dashboard/' + this.orderService.dashReturnId);
        },
        error => {
          this.alertService.error('Error cancelling order: ' + error);
        });
      }
    });
  }

  onOpenPdf() {
    const dialogRef = this.dialog.open(ModalPdfComponent, { minHeight: '95vh', minWidth: '95vw'});
  }

  onDownloadPdf() {
    const fileURL = `${environment.apiUrl}/PdfGen/` + this.orderService.currentOrderId;
    window.open(fileURL, '_blank');
  }

  isValidFormCheck(pageIdx: number) {
    return this.isPageValid[pageIdx];
  }

  selectStep(e: any) {
    let i = e.selectedIndex;
    this.formService.pageIdx = i;
    this.router.navigate(['pages/' + i]);
    this.currentStep = i;
  }

  onBackToOrder() {
    this.router.navigate(['orderdetails/' + this.orderService.currentOrderId]);
  }
}
