import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CompanyService } from 'src/app/services/company.service';
import { UserService } from 'src/app/services/user.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from 'src/app/services/order.service';
import { DialogService } from 'src/app/services/dialog.service';
import { GenericResponse, OrderAttachment, OrderForm, OrderFormFieldData, PdfGen, Taker } from '../models/order';
import { environment } from 'src/environments/environment';
import { Package } from '../models/form';
import { CompanyOrders, Customer, EmailMessage, GenericString } from '../models/customer';
import { saveAs } from 'file-saver';
import { LoaderService } from '../services/loader.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../services/authentication.service';
import { map } from 'rxjs/operators';
import { ExternalLists } from '../helpers/external-lists';
declare var bootstrap: any;

@Component({
  selector: 'order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {
  @Input() order: any;

  selectedHdr: string = 'orders';
  loaderLocked: boolean = false;
  orderDate: string;
  package: any;
  packageName: string;
  customer: any;
  orderDetails: CompanyOrders;
  takers: any;
  takerTitle: string;
  editing: boolean = false;
  takerForms: UntypedFormGroup[];
  attachments: OrderAttachment[];

  constructor(private http: HttpClient, public companyService: CompanyService, public orderService: OrderService,
            public userService: UserService, public route: ActivatedRoute, public dialogSvc: DialogService,
            private router: Router, private AuthSvc: AuthenticationService,
            public loaderService: LoaderService, private formBuilder: UntypedFormBuilder) {
  }

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    if(!this.userService.currentCompany && !this.userService.currentCustomer) this.router.navigateByUrl('/login');
    this.route.params.subscribe(params => {
      const oid = params.oid;
      this.http.get(`${environment.apiUrl}/OrderForm/` + oid).toPromise().then(res => {
        this.order = res as OrderForm;
        this.orderDate = this.getFormattedDate(new Date(this.order.OrderDate));
        this.http.get(`${environment.apiUrl}/Package/` + this.order.PackageId).subscribe(res => {
          this.package = res as Package;
          this.packageName = this.package.PackageName;
        });
        this.http.get(`${environment.apiUrl}/Customer/` + this.order.CustomerId).subscribe(res => {
          this.customer = res as Customer;
        });
      });
      this.getOrder(params.oid);
      this.getTakers(params.oid);
    });
  }

  getOrder(oid: any) {
    let gs = new Taker();
    gs.OrderId = 0;
    gs.StartDate = new Date('12/31/2000');
    gs.FinishDate = new Date('12/31/2100');
    gs.TakerId = this.userService.userRole === 'admin' ? this.userService.currentCompany?.CompanyId : this.userService.currentCustomer?.CompanyId;
    gs.TakerHint = 'All';
    
    this.http.post(`${environment.apiUrl}/Company/OrderSearch/`, gs).subscribe(res => {
      let data = res as CompanyOrders[];
      this.orderDetails = data.filter(x => x.OrderId == oid)[0];

      this.http.get(`${environment.apiUrl}/OrderAttachment/Order/` + oid).toPromise().then(res => {
        this.attachments = res as OrderAttachment[];
      });
    });
  }

  checkCompletion() {
    return this.takers?.filter(x => x.EmailAddress == this.userService.currentEmail)[0]?.FinishDate != null;
  }

  getTakers(oid: any) {
    this.http.get(`${environment.apiUrl}/Taker/Order/` + oid).toPromise().then(res => {
      this.takers = res as Taker[];
      this.takers.sort((a, b) => a.StartSeq - b.StartSeq);
      this.takerForms = this.takers.map(t => this.formBuilder.group({
        TakerId: [t.TakerId],
        TakerName: [t.TakerName],
        TakerHint: [t.TakerHint],
        OrderId: [t.OrderId],
        EmailAddress: [t.EmailAddress],
        FaxNumber: [t.FaxNumber],
        SendMethod: [t.SendMethod],
        StartDate: [t.StartDate],
        FinishDate: [t.FinishDate],
        StartSeq: [t.StartSeq]
      }));
      this.takerTitle = this.takers.filter(x => x.EmailAddress == this.userService.currentEmail)[0]?.TakerHint;
    });
  }

  onCompleteOrder() {
    this.loaderLocked = true;
    let oid = '' + this.orderService.currentOrderId;
    let tid = this.takers.filter(x => x.EmailAddress == this.userService.currentEmail)[0].TakerId;
    this.loaderService.loadPage(oid, tid, '/pages/0');
  }

  onBack() {
    this.router.navigateByUrl('/dashboard/' + this.orderService.dashReturnId);
  }

  getFormattedDate(date) {
    var year = date?.getFullYear();
  
    var month = (1 + date?.getMonth()).toString();
    month = month?.length > 1 ? month : '0' + month;
  
    var day = date?.getDate().toString();
    day = day?.length > 1 ? day : '0' + day;
    
    return month + '/' + day + '/' + year;
  }

  onAsset(file: string) {
    let str = new GenericString();
    str.ValueDes = file;
    let ext = ExternalLists.mimeType(file.substring(file.lastIndexOf('.') + 1));
    this.getFile(`${environment.apiUrl}/OrderAttachment/download`, str, ext).subscribe(data => {
      saveAs(data, file);
    });
  }

  getFile(path: string, str: GenericString, typ: string): any {
    return this.http.post<any>(path, str, { responseType: 'blob' as 'json'}).pipe(map(res => {return new Blob([res], { type: typ });  }));
  }

  onDownload() {
    this.loaderLocked = true;
    let pdfFile = 'Expidoc_' + this.order.OrderId + '.pdf';
    let url = `${environment.apiUrl}/PdfGen/` + pdfFile;
    this.orderService.getPdf(url).subscribe(data => {
      saveAs(data, pdfFile);
      this.loaderLocked = false;
    }, error => {
      let url = `${environment.apiUrl}/PdfGen/buildpkg/` + this.order.OrderId + '/1';
      this.http.get(url).toPromise().then(res => {
        let rtn = res as PdfGen;
        if(rtn.ResultCode == 1) {
          this.loaderLocked = false;
          this.onDownload();
        } else {
          this.dialogSvc.openMessageDialog("Rebuild File", rtn.ErrorMessage);
          this.loaderLocked = false;
        }
      });
    });
  }

  onHover(event: any) {
    new bootstrap.Tooltip(event.target).show();
  }

  onResendLink(taker: Taker) {
    this.loaderLocked = true;
    let tmplt = 'wq' + taker.TakerHint + 'invite.html';
    let params = new HttpParams().append('subject', 'Expidoc Data Request').append('template', tmplt);

    let eurl = `${environment.apiUrl}/Mail/sendTaker/` + taker.TakerId;
    this.http.get(eurl, {params: params}).toPromise().then(res => {
      this.loaderLocked = false;
      this.dialogSvc.openMessageDialog('Send Email', 'Email has been sent');
    }, error => {
      this.loaderLocked = false;
      this.dialogSvc.openMessageDialog('Send Email', 'Error sending email :' + error);
    });
  };

  onResendOrder(taker: Taker) {
    if (taker.SendMethod == 'fax' || taker.SendMethod == 'both') {
      this.loaderLocked = true;
      let url = `${environment.apiUrl}/Mail/fax/` + this.order.OrderId;
      this.http.get(url).toPromise().then(res => {
        this.loaderLocked = false;
        this.dialogSvc.openMessageDialog('Send Fax', 'Fax has been sent');
      }, error => {
        this.loaderLocked = false;
        this.dialogSvc.openMessageDialog('Send Fax', 'Error sending fax :' + error);
      });
    }
    if (taker.SendMethod == 'email' || taker.SendMethod == 'both') {
      this.loaderLocked = true;
      let pdfFile = 'Expidoc_' + this.order.OrderId + '.pdf';

      let offds = new Array<OrderFormFieldData>();
      let offd = new OrderFormFieldData();
      offd.DataId = 0;
      offd.DataMask = '';
      offd.FieldId = 0;
      offd.FieldType = 'field';
      offd.FieldValue = taker.TakerName;
      offd.PdfField = 'custname';
      offds.push(offd);

      let em = new EmailMessage();
      em.Tos = taker.EmailAddress;
      em.CCs = '';
      em.Subject = 'The documents you requested are attached'
      em.Body = '';
      em.Template = 'expidocattached.html';
      em.Data = JSON.stringify(offds);
      em.Attachment = pdfFile;
      
      let url = `${environment.apiUrl}/Mail/email`;
      this.http.post(url, em).subscribe(res => {
        let resp = res as GenericResponse;
        if(resp.ReturnCode) {
          this.loaderLocked = false;
          this.dialogSvc.openMessageDialog('Send Email', 'Email has been sent');
        } else {
          this.loaderLocked = false;
          this.dialogSvc.openMessageDialog('Send Email', 'Error resp sending email :' + resp.DetailMessage);
        }
      }, error => {
        this.loaderLocked = false;
        this.dialogSvc.openMessageDialog('Send Email', 'Error sending email :' + error);
      });
    }
  }

  onEdit() {
    this.editing = true;
    this.takerForms = this.takers.map(t => this.formBuilder.group({
      TakerId: [t.TakerId],
      TakerName: [t.TakerName],
      TakerHint: [t.TakerHint],
      OrderId: [t.OrderId],
      EmailAddress: [t.EmailAddress],
      FaxNumber: [t.FaxNumber],
      SendMethod: [t.SendMethod],
      StartDate: [t.StartDate],
      FinishDate: [t.FinishDate],
      StartSeq: [t.StartSeq]
    }));
  }

  onCancel() {
    this.editing = false;
  }

  onSave() { 
    this.takerForms.forEach(takerForm => {
      let taker = takerForm.value as Taker;
      this.http.put(`${environment.apiUrl}/Taker/` + taker.TakerId, taker).subscribe(res => {
        this.getTakers(this.order.OrderId);
      });
    });
    this.editing = false;
  }

}
