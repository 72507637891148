import { HttpClient, HttpParams } from "@angular/common/http";
import { SubscriptionLike } from "rxjs";
import { environment } from "src/environments/environment";
import { Taker } from "../models/order";
import { OrderService } from "../services/order.service";
import { ContactTakers, ContactTakerStep } from "./contact-takers";
import { GenericInt } from "../models/customer";

export class PageNextStep {
    lastError: string = '';
    orderService: OrderService;
    http: HttpClient;
    cTakers: ContactTakers;
    pdfAsked: boolean = false;
    pdfDel: boolean = false;
    pdfShow: boolean = false;
    cSteps: number = 0;
    takerStep = -1;
    pageReturn: string = '';
    contactCompletedSubscrition: SubscriptionLike;

    constructor(orderSvc: OrderService, httpCli: HttpClient, ) {
        this.orderService = orderSvc;
        this.http = httpCli;
        this.cTakers = new ContactTakers(this.http, orderSvc);
        this.contactCompletedSubscrition = this.cTakers.contactCompleted.subscribe(res =>
        {
            let rtn = res as boolean;
            //console.log(' contact completed reporting ' + rtn);
            this.orderService.pageStepsDone(rtn);
        });
    }

    public evaluate(nextAction: string): string {
        this.orderService.pageResult = true;
        this.lastError = '';

        let rtn = 'thanks';

        let actions = nextAction.split(';');

        for(let a = 0; a < actions.length; a++) {
            console.log('doing step (' + a + ') ' + actions[a]);
            let act = this.doStep(actions[a]);
            console.log('  returned ' + act);

            if(act != '') {
                rtn = act;
                a = actions.length + 1;
            }
        }

        this.cSteps = this.cTakers.steps.length;
        this.pageReturn = rtn;

        this.checkTaker();

        return rtn;
    }

    doStep(step: string): string {
        let rtn = '';

        let parts = step.split(':');

        if(parts[0] == '3rdp') rtn = '3rdparty/' + parts[1];
        
        /*if(parts[0] == 'auto') {
            this.orderService.nextTakerHint = parts[1];
            //some service.sendInvite(parts[1]);
            let eurl = `${environment.apiUrl}/Mail/invite/` + this.formService.nextTaker.TakerId;
            this.http.get(eurl).toPromise().then(res => {
              this.router.navigate(['thanks']);
            });
          }*/

        if(parts[0] == 'checktaker') {
            let tmplt = 'wq' + parts[1].toLowerCase() + 'invite.html';
            let ctUrl = `${environment.apiUrl}/Mail/sendTaker/`;
            let params = new HttpParams().append('subject', 'Expidoc Data Request').append('template', tmplt);
            let dtls = 'taker:' + parts[1];
            if(parts.length >= 3) dtls += ':' + parts[2];
            this.cTakers.addStep('D', ctUrl, params, dtls, '');
        }

        if(parts[0] == 'deldoc') {
            this.pdfDel = true;
            //if(this.pdfAsked) this.cTakers.addStep('DelPDF', '', null, '', '');
        }

        if(parts[0] == 'docs') {
            if(!this.pdfAsked) {
                let ctUrl = `${environment.apiUrl}/PdfGen/gen/` + this.orderService.currentOrderId + '/0';
                this.cTakers.addStep('PDF', ctUrl, null, '', '');
                this.pdfAsked = true;
            }
        }

        if(parts[0] == 'invite') {
            let ctUrl = `${environment.apiUrl}/Mail/sendTaker/`; //+ this.orderService.currentTaker.TakerId;
            let params = new HttpParams().append('subject', 'Expidoc Form Update').append('template', 'wqassociateinvite.html');
            let det = '';
            //if(parts.length >= 1) det = parts[1];

            if(parts.length >= 2) det = 'taker:' + parts[1];
            if(parts.length >= 3) det += ':' + parts[2];

            this.cTakers.addStep('E', ctUrl, params, det, '');
        }

        if(parts[0] == 'send') {
            let ctUrl = `${environment.apiUrl}/Mail/sendTaker/`; //+ this.orderService.currentTaker.TakerId;
            let params = new HttpParams().append('subject', 'Expidoc Form Update').append('template', 'wqdocsattached' + parts[1] + '.html');
            let det = '';
            //if(parts.length >= 1) det = parts[1];

            if(parts.length >= 2) det = 'taker:' + parts[1];
            if(parts.length >= 3) det += ':' + parts[2];

            this.cTakers.addStep('E', ctUrl, params, det, '');
        }

        if(parts[0] == 'thanks') {
            rtn = 'thanks';
            //console.log('  finishing taker ' + this.orderService.currentTaker.TakerId);

            this.http.get(`${environment.apiUrl}/Taker/Finished/` + this.orderService.currentTaker.TakerId).toPromise().then(res => {
                let rtn = res as GenericInt;
                if(rtn.IntValue == 0) {
                    let ctUrl = `${environment.apiUrl}/Mail/sendFinal/` + this.orderService.currentOrderId;
                    let params = new HttpParams().append('subject', 'Expidoc Forms').append('template', 'expidosendcattached.html');
                    this.cTakers.addStep('D', ctUrl, params, '', '');
        
                }
            });
        }
        return rtn;
    }

    checkTaker() {
        this.takerStep++;
        //console.log('checking step ' + this.takerStep);
        if(this.takerStep < this.cTakers.steps.length && this.cTakers.steps.length > 0) {
            let meth = this.cTakers.steps[this.takerStep].MethodDetails;
            //console.log('   for ' + meth);
            if(meth.length > 0) {
                let parts = meth.split(':');
                if(parts[0] == 'taker' || (parts[0] == 'send' && parts.length > 1)) {
                    let step = this.cTakers.steps[this.takerStep];
                    let ctUrl = `${environment.apiUrl}/Taker/Check/` + this.orderService.currentOrderId + '/' + parts[1];
                    //console.log('   checking taker: ' + ctUrl);

                    this.http.get(ctUrl).toPromise().then(res => {
                        let ts = res as Taker[];

                        let t = ts[0];
                        //console.log('   checktaker returned ' + JSON.stringify(t));
                        
                        if(t.TakerId > 0) {
                            step.Url = step.Url + t.TakerId;
                            let dtl = '';
                            if(parts.length >= 3) dtl = parts[2];
                            step.MethodDetails = dtl;

                            if(t.SendMethod == 'fax') {
                                //console.log('E adding fax step');
                                //step.MethodType = 'F';
                                //step.Url = `${environment.apiUrl}/Mail/sendFax/` + this.orderService.currentOrderId;
                                step.QueryParams = new HttpParams().append('fax', '1' + t.FaxNumber.replace(/[^a-z0-9\s]/gi, '').replace(/[_\s]/g, '')).append('subject', 'Expidoc Forms').append('template', 'WQCaseFax.txt');
                                //step.MethodDetails += ';addpdf';
                            }

                            if(t.SendMethod == 'link') {
                                //this.pdfShow = true;
                                step.MethodType = 'L';
                                step.MethodDetails = '' + t.TakerId;
                            }
                            
                            // if(t.SendMethod == 'both' || t.SendMethod == 'email') {
                            //     //console.log('both adding fax step');
                            //     //let url = step.Url; // `${environment.apiUrl}/Mail/sendFax/` + this.orderService.currentOrderId;
                            //     //let details = step.MethodDetails; // += ';addpdf';
                            //     let params = new HttpParams().append('fax', '1' + t.FaxNumber?.replace(/[^a-z0-9\s]/gi, '').replace(/[_\s]/g, '')).append('subject', 'Expidoc Forms').append('template', 'WQCaseFax.txt');
                            //     this.cTakers.addStep('E', step.Url, params, step.MethodDetails, step.RoutePath);
                            // }

                            //console.log('   step is ' + JSON.stringify(step));
                            this.cTakers.steps[this.takerStep] = step;

                            this.checkTaker();
                        } else {
                            this.lastError = t.TakerName;
                            //console.log('check taker ERROR: ' + this.lastError);
                            //rtn = 'ERROR';
                        }
                    });
                } else {
                    this.checkTaker();
                }
            } else {
                this.checkTaker();
            }
        } else {
            if(this.pdfDel && this.pdfAsked && !this.pdfShow) this.cTakers.addStep('DelPDF', '', null, '', '');

            //console.log('pg next step starting contact takers #' + this.cTakers.steps.length);
            //console.log(JSON.stringify(this.cTakers.steps));

            this.cTakers.showPdf = this.pdfShow;
            this.cTakers.start();
        }
    }
}
