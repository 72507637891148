import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Company, CompanyLogin, Subscription, CompanySubscription, CompanyFormData, CompanyFieldXref, CompanyCalendarEventType, CompanyDistribution, DistributionGroup } from 'src/app/models/customer';
import { CompanyService } from 'src/app/services/company.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { DialogCompanyLoginComponent } from '../dialog-company-login/dialog-company-login.component';
import { DialogSubscriptionComponent } from '../dialog-subscription/dialog-subscription.component';
import { ToastService } from 'src/app/services/toast.service';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.scss']
})
export class CompanySettingsComponent implements OnInit {
  selectedHdr: string = 'setting';
  company: Company;
  fileState: number = 1;
  chosenFile: File;
  chosenFileName: string = '';
  uploadBtnTitle: string = 'Upload Logo';
  logoFile: string = 'BlankLogo.png';
  logins: CompanyLogin[];
  formData: CompanyFormData[];
  subscription: Subscription;
  companySubscription: CompanySubscription;
  userSettings: UntypedFormGroup = new UntypedFormGroup({});
  saveSubmitted: boolean = false;
  distGroup: DistributionGroup[];
  distSettings: CompanyDistribution[];

  @ViewChild('fileInput') fileInput:ElementRef;

  constructor(private http: HttpClient, public companyService: CompanyService, public userService: UserService,
    private router: Router, public dialogLogin: MatDialog, public dialogSub: MatDialog, public toastService: ToastService,private formBuilder: UntypedFormBuilder) {
      this.userSettings = this.formBuilder.group({
        CustomerTitle: ['', Validators.required],
        CustomerGroupTitle: ['', Validators.required],
        NewCustMethod: ['', Validators.required],
        EmailDomain: [''],
        ActivationCd: ['']
      });
  }

  ngOnInit(): void {
    if(this.userService.currentCompany == null) this.router.navigateByUrl('/login');
    //console.log(JSON.stringify(this.userService.currentCompany));

    this.company = new Company();
    this.logins = new Array<CompanyLogin>();
    this.subscription = new Subscription();
    this.companySubscription = new CompanySubscription();

    let cid = this.userService.currentCompany.CompanyId;

    this.http.get(`${environment.apiUrl}/Company/` + cid).subscribe(res => {
      this.company = res as Company;
      this.userSettings.patchValue({
        CustomerTitle: this.company.CustomerTitle,
        CustomerGroupTitle: this.company.CustomerGroupTitle,
        NewCustMethod: this.company.NewCustMethod,
        EmailDomain: this.company.EmailDomain,
        ActivationCd: this.company.ActivationCd
      });
      this.setConditionalValidator('EmailDomain', 'NewCustMethod', 'C');
      this.setConditionalValidator('ActivationCd', 'NewCustMethod', 'A', true);

      this.refresh();

      if(this.company.LogoFile == null) this.company.LogoFile = '';
      if(this.company.LogoFile != '') {
        this.uploadBtnTitle = 'Replace Logo';
        this.logoFile = this.company.LogoFile;
      }
    });

    this.http.get(`${environment.apiUrl}/CompanyDistribution/group`).subscribe(res => {
      this.distGroup = res as DistributionGroup[];
    });
  }

  get u() { return this.userSettings.controls; }

  setConditionalValidator(field: string, validator: string, value: any, ne: boolean = false) {
    const fieldObj = this.userSettings.get(field);
    if ((!ne && this.userSettings.get(validator).value == value) || (ne && this.userSettings.get(validator).value != value)) {
      fieldObj.setValidators([Validators.required]);
    }

    this.userSettings.get(validator).valueChanges.subscribe(cust => {
      if ((!ne && cust == value) || (ne && cust != value)) {
        fieldObj.setValidators([Validators.required]);
      } else {
        fieldObj.clearValidators();
      }
      fieldObj.updateValueAndValidity();
    }
    );
  }

  refresh() {
    this.http.get(`${environment.apiUrl}/CompanyLogin/company/` + this.company.CompanyId).subscribe(res => {
      this.logins = res as CompanyLogin[];
    });

    this.http.get(`${environment.apiUrl}/CompanySubscription/company/` + this.company.CompanyId).subscribe(res => {
      this.companySubscription = res[0] as CompanySubscription;
      //console.log(JSON.stringify(this.companySubscription));
      this.http.get(`${environment.apiUrl}/Subscription/` + this.companySubscription.SubscriptionId).subscribe(res => {
        this.subscription = res as Subscription;
        //console.log(JSON.stringify(this.subscription));
      });
    });

    this.http.get(`${environment.apiUrl}/CompanyFormData/Fields`).subscribe(res => {
      this.companyService.fieldXrefs = res as CompanyFieldXref[];

      this.http.get(`${environment.apiUrl}/CompanyFormData/Company/` + this.company.CompanyId).subscribe(res => {
        this.formData = res as CompanyFormData[];
      });
    });

    this.http.get(`${environment.apiUrl}/CompanyDistribution/company/` + this.company.CompanyId).subscribe(res => {
      this.distSettings = res as CompanyDistribution[];
    });
  }

  onSave(section: string = '') {
    this.saveSubmitted = true;
    if (section === 'userSettings' && this.userSettings.invalid) {
      return;
    }
    let cid = this.company.CompanyId;
    if (section === 'userSettings') {
      this.company.CustomerTitle = this.userSettings.get('CustomerTitle').value;
      this.company.CustomerGroupTitle = this.userSettings.get('CustomerGroupTitle').value;
      this.company.NewCustMethod = this.userSettings.get('NewCustMethod').value;
      this.company.EmailDomain = this.userSettings.get('EmailDomain').value;
      this.company.ActivationCd = this.userSettings.get('ActivationCd').value == '' ? '-' : this.userSettings.get('ActivationCd').value;
    }

    this.http.put(`${environment.apiUrl}/Company/` + cid, this.company).subscribe(res => {
      this.toastService.addToast({message: "Changes saved"});
    },
    error => {
      console.log(error.error());
      this.toastService.addToast({message: "Error: Unable to save changes"});
    });
  }

  onSaveDist() {
    console.log(JSON.stringify(this.distSettings));
    this.http.post(`${environment.apiUrl}/CompanyDistribution/save`, this.distSettings).subscribe(res => {
      this.toastService.addToast({message: "Changes saved"});
      this.refresh();
    },
    error => {
      console.log(error);
      this.toastService.addToast({message: "Error: Unable to save changes"});
    });
  };

  displayDistGroup(id: number): string {
    let rtn = '';
    if(this.distGroup != null) {
      let found = this.distGroup.find(dg => dg.DistributionGroupId == id);
      if(found != null) rtn = found.DistributionName;
    }
    return rtn;
  }

  handleFileInput(files: FileList) {
    this.chosenFile = files.item(0);
    this.chosenFileName = 'Comp' + this.company.CompanyId + '_' + files.item(0).name;
    this.company.LogoFile = this.chosenFileName;
    this.logoFile = this.company.LogoFile;
    this.saveFile();
  }

  saveFile() {
    const uploadData = new FormData();
    uploadData.append('file', this.chosenFile, this.chosenFileName);
    //let lid = this.chosenFileName.lastIndexOf('.');
    //let ext = this.chosenFileName.substring(lid + 1);
    //console.log('uploading ' + this.chosenFileName);

    this.http.post(`${environment.apiUrl}/Company/UploadLogo/` + this.company.CompanyId, uploadData).toPromise().then(res => {
      this.onSave();
    });
  }

  onNewLogin() {
    this.userService.currentDialogCompanyLogin = new CompanyLogin();
    this.userService.currentDialogCompanyLogin.CompanyId = this.company.CompanyId;
    //this.userService.currentDialogCompanyLogin.CompanyLoginId = 0;
    this.userService.currentDialogCompanyLogin.AccessValues = 'x';
    this.userService.currentDialogCompanyLogin.PrimaryContact = 'N';

    const dialogRef = this.dialogLogin.open(DialogCompanyLoginComponent, { minHeight: '300px', minWidth: '50vw'});

    dialogRef.afterClosed().subscribe(() => {
     this.refresh();
    });
  }

  onEditLogin(login: CompanyLogin) {
    this.userService.currentDialogCompanyLogin = login;
    
    const dialogRef = this.dialogLogin.open(DialogCompanyLoginComponent, { minHeight: '300px', minWidth: '50vw'});

    dialogRef.afterClosed().subscribe(() => {
     this.refresh();
    });
  }

  onSaveData(): void {
    this.formData.forEach(fd => {
      this.http.put(`${environment.apiUrl}/CompanyFormData/` + fd.CompanyDataId, fd).toPromise().then(res => {
      },
      error => {
        this.toastService.addToast({message: "Error: Unable to save changes"});
        return;
      });
    })
    this.toastService.addToast({message: "Changes saved"});
  }

  getPackageName(code: string): string {
    let rtn = 'Unknown';

    if(code == 'form') rtn = 'Form Builder';
    if(code == 'qual') rtn = 'Leave Qualifier';
    if(code == 'full') rtn = 'Full Service';
    if(code == 'build') rtn = 'Build a Package';

    return rtn;
  }

  getPriContact(val: string): string {
    let rtn = '';
    if(val == 'Y') rtn = 'Yes';
    return rtn;
  }

  onManageSubscription() {
    const dialogRef = this.dialogSub.open(DialogSubscriptionComponent, { minHeight: '300px', minWidth: '70vw', data: this.companySubscription});

    dialogRef.afterClosed().subscribe(() => {
     this.refresh();
    });
  }

}
